/*
  PAGE TEMPLATE
  A template to display fully editable pages
*/

import React, { useContext } from 'react'
import { graphql } from 'gatsby'

import PostContextProvider from '@base/utils/contexts/posts-context'
import { PageContext } from '@base/utils/contexts/page-context'
import PageLayout from '@base/layout/page-layout'
import Blocks from '@base/layout/blocks'

import { getGeneralData } from '@api/general-data'
import ModalBuy from '@elements/modal-buy'

const DynamicLandingPage = ({ pageContext, data }) => {
  const generalData = getGeneralData(pageContext, data)
  const minifiedPageContext = generalData.pageContext
  const globalData = generalData.globalData
  const modalData = generalData.modalData
  const contentSections = data.blocksData.contentSections

  // Use the project's own "Buy Game" Modal Component
  const { modalCont } = useContext(PageContext)
  const [modalContent, setModalContent] = modalCont
  const CustomModalBuy = () => {
    return (
      <ModalBuy
        pageContext={pageContext}
        modalData={modalData}
        product={modalContent.data && modalContent.data.link ? modalContent.data.link : 'base-game'}
        content={modalContent.data.modalContent}
        location={modalContent.data.eventElement}
      />
    )
  }

  return (
    <PageLayout
      pageContext={minifiedPageContext}
      globalData={globalData}
      modalData={modalData}
      modalBuyComponent={CustomModalBuy}
    >
      <PostContextProvider>
        <Blocks
          pageContext={minifiedPageContext}
          globalData={globalData}
          blocks={contentSections}
        />
      </PostContextProvider>
    </PageLayout>
  )
}
export default DynamicLandingPage


export const query = graphql`
  query DynamicLandingPageQuery($id: String!) {
    pageData: strapiLandingPage(id: { eq: $id }) {
      title
      slug
      locale
      disablePageBreadcrumbs
      disableNewsletterSignup
      disableBuyGameSection
      metadata {
        metaTitle
        metaDescription
        canonicalURL
        disablePageIndexing
        shareImage {
          url
          localFile {
            publicURL
          }
        }
        socialMediaShare {
          socialNetwork
          title
          description
          image {
            localFile {
              publicURL
            }
          }
        }
      }
      themeModifier {
        hideMainNav
        removeFooterSocial
        removeFooterStoreLinks
        removeLanguageSelector
      }
      localizations {
        data {
          attributes {
            locale
            slug
            title
            publishedAt
          }
        }
      }
    }
    blocksData: strapiLandingPage(id: { eq: $id }) {
      contentSections {
        ...HeroBlock
        ...VideoBlock
        ...CardsBlock
        ...PostsBlock
      }
    }
  }
`
export { Head } from '@base/layout/head'