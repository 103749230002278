import React, { useRef } from 'react'
import { motion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'

import useLockBodyScroll from '@base/utils/hooks/useLockBodyScroll'
import Image from '@base/parts/image'
import Markdown from '@base/parts/markdown'

import Stores from '@elements/store/store-buttons'

const ModalBuy = (props) => {
  const modalPreview = useRef(null)
  const content = props.content
  const pageContext = props.pageContext
  const brandData = props.modalData.brandData
  const gameData = props.modalData.gameData

  // Lock <body> scroll when purchase modal is open
  useLockBodyScroll(modalPreview.current)

  return (
    <>
      <div
        ref={modalPreview}
        className="modal-preview absolute top-0 h-full w-full object-cover"
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="modal-preview-background h-full w-full"
        >
          {content && content.media && (
            <Image
              media={content.media}
              className="h-full w-full"
            />
          )}
        </motion.div>
        {content && content.logo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className={`modal-preview-logo ${(content.logoPosition && content.logoPosition !== null) ? content.logoPosition : 'centerCenter'}`}
          >
            <Image
              media={content.logo}
            />
          </motion.div>
        )}
      </div>
      <div className="modal-buy-content h-auto absolute top-0 lg:top-12 right-0 lg:right-8 xl:right-44 flex flex-col text-base text-white w-full lg:w-auto">
        <div className="modal-buy-brand relative py-8 px-8">
          <Image
            media={brandData?.fullLogo ? brandData.fullLogo : brandData?.logo}
            className="modal-buy-brand-logo"
          />
          <StaticImage
            src="../../assets/img/MyM-yellow-dust-texture.png"
            alt=""
            className="h-auto w-full gatsby-absolute pointer-events-none top-0 left-0 rotate-180"
            loading="lazy"
          />
          <StaticImage
            src="../../assets/img/MyM-yellow-dust-texture-top.png"
            alt=""
            className="h-auto w-full gatsby-absolute pointer-events-none top-0 left-0 opacity-50"
            loading="lazy"
          />
        </div>
        {content && content.title && (
          <div className="modal-buy-title-wrapper relative overflow-hidden">
            <h3 className="modal-buy-title">
              <Markdown children={content.title} />
            </h3>
            <StaticImage
              src="../../assets/img/MyM-texture-diagonal-stripe.png"
              alt=""
              className="modal-buy-title-mask gatsby-absolute pointer-events-none left-0 top-0 h-full w-full lg:w-[48%]"
            />
          </div>
        )}
        {gameData && (
          <Stores
            pageContext={pageContext}
            layout={content && content.storeLayout ? content.storeLayout : 'categories'}
            product={props.product}
            gameData={gameData}
            className="p-0 m-0 list-none"
            legacy={content && content.legacy}
            eventAction={content && content.eventAction}
            eventCategory={content && content.eventCategory}
            eventElement={content && content.eventElement}
            eventType={content && content.eventType}
          />
        )}
        <div className="h-20 relative overflow-hidden">
          <StaticImage
            src="../../assets/img/MyM-texture-diagonal-stripe-large.png"
            alt=""
            className=" gatsby-absolute mix-blend-luminosity w-full h-full opacity-90"
            loading="lazy"
          />
        </div>
      </div>
    </>
  )
}

export default ModalBuy